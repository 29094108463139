import React, { useState } from "react";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";
import Pagination from "../../components/pageProps/shopPage/Pagination";
import ProductBanner from "../../components/pageProps/shopPage/ProductBanner";
import ShopSideNav from "../../components/pageProps/shopPage/ShopSideNav";

const Shop = () => {
  const [itemsPerPage, setItemsPerPage] = useState(9);
  

  return (
    <div className="max-w-container  mx-auto px-4">
    <Breadcrumbs title="Sản phẩm" />
    {/* ================= Products Start here =================== */}
    <div className="w-full h-full flex pb-20 gap-10"> 
      <div className="w-[20%] lgl:w-[25%] hidden mdl:inline-flex h-full">
        <ShopSideNav />
      </div>
      {/* <div className="w-full mdl:w-[80%] lgl:w-[75%] h-full flex flex-col gap-10"> */}
      <div className="w-[80%] center mx-auto h-full flex flex-col gap-10">

        {/* <ProductBanner itemsPerPageFromBanner={itemsPerPageFromBanner} /> */}
        <Pagination itemsPerPage={itemsPerPage} />
      </div>
  </div>
    {/* ================= Products End here ===================== */}
  </div>
  );
};

export default Shop;
